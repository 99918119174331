import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/DefaultNewsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`The First Shanti-Aid Chai & Chat`}</h1>
    <p><em parentName="p">{`great feedback from our users`}</em></p>
    <p>{`contact us to find out more`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9545d7251028f29706527b80cbc04910/3fe1b/chainchat.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "107.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAWABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQDAQL/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAZ/FOeWxSZ5EDgKj/8QAHRAAAgICAwEAAAAAAAAAAAAAAQIAAxESBBMUI//aAAgBAQABBQJVKpZXk+YiL9Y69THkOItAqN7bHXM//8QAGBEAAgMAAAAAAAAAAAAAAAAAABAREkH/2gAIAQMBAT8BgoYv/8QAGBEAAgMAAAAAAAAAAAAAAAAAABAREkH/2gAIAQIBAT8Bksav/8QAHxABAAICAAcAAAAAAAAAAAAAAQAhAhEQEjFRYXGh/9oACAEBAAY/Aga9w5bfEvL5EXp2g4ty9RyXcOH/xAAdEAEAAgEFAQAAAAAAAAAAAAABABEhMUFRYXGB/9oACAEBAAE/ISgOw0lCQvxUBAY8WZfpxIRtLYlIK3k8gUxL9QBsQYuf/9oADAMBAAIAAwAAABCzFz7/xAAZEQEBAQADAAAAAAAAAAAAAAABABExseH/2gAIAQMBAT8QQmNg578m5f/EABkRAAMAAwAAAAAAAAAAAAAAAAABESFBsf/aAAgBAgEBPxBZF64Jin//xAAeEAEAAwACAgMAAAAAAAAAAAABABEhMVFBYYGRof/aAAgBAQABPxAqs85tfsVilo+w9Smh9xvmoB5hrWxljpbkxigOl7D5gb5B79x9qgBVdS5U3uf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Chai & Chat",
            "title": "Chai & Chat",
            "src": "/static/9545d7251028f29706527b80cbc04910/4b190/chainchat.jpg",
            "srcSet": ["/static/9545d7251028f29706527b80cbc04910/e07e9/chainchat.jpg 200w", "/static/9545d7251028f29706527b80cbc04910/066f9/chainchat.jpg 400w", "/static/9545d7251028f29706527b80cbc04910/4b190/chainchat.jpg 800w", "/static/9545d7251028f29706527b80cbc04910/e5166/chainchat.jpg 1200w", "/static/9545d7251028f29706527b80cbc04910/b17f8/chainchat.jpg 1600w", "/static/9545d7251028f29706527b80cbc04910/3fe1b/chainchat.jpg 1846w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      